import React from "react";
import { useInView } from "react-intersection-observer";
import "./Skills.scss";

const skills = [
  { name: "Backend Infrastructure", level: 90 },
  { name: "Golang", level: 85 },
  { name: "Deep Learning", level: 85 },
  { name: "Data Structures & Algorithms", level: 85 },
  { name: "PyTorch", level: 80 },
  { name: "Python", level: 75 },
  { name: "C++", level: 65 },
  { name: "Image Processing", level: 60 },
];

const Skills = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div ref={ref} className="skills-section">
      <div className="skills-section-title">Technical Skills</div>
      <div className="skills-section-grid">
        {skills.map((skill, index) => (
          <div className="skills-section-item" key={index}>
            <div className="skills-section-item-name">{skill.name}</div>
            <div className="skills-section-item-bar">
              <div
                className="skills-section-item-fill"
                style={{
                  width: inView ? `${skill.level}%` : "0%",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
