import React from "react";
import "./Achievements.scss";

const achievements = [
  { text: "Kaggle Competitions Expert (Best Rank: 1351, 99th percentile)", icon: "🏆" },
  { text: "Undergrad Merit Scholarship (Top 3% in batch)", icon: "🎓" },
  { text: "DAAD-WISE Fellowship Recipient (2017)", icon: "🇩🇪" },
  { text: "IIT-JEE All India Rank 4308 (99.8th percentile)", icon: "📈" },
];

const Achievements = () => {
  return (
    <div className="achievements-section">
      <div className="achievements-section-title">Achievements & Awards</div>
      <div className="achievements-section-list">
        {achievements.map((achievement, index) => (
          <div className="achievements-section-list-item" key={index}>
            <div className="achievements-section-icon">{achievement.icon}</div>
            <div className="achievements-section-content">
              <div className="achievements-section-text">{achievement.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;

