import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Portfolio.scss';

const items = [
  { src: '/images/openkbp.png', title: 'OpenKBP 2020', link: 'https://medium.com/@sanchithira76/my-3rd-place-solution-to-the-openkbp-challenge-c0cbdd79de11' },
  { src: '/images/PKU.png', title: 'PKU 3D Object Detection', full_img: '/images/PKU_full.png' },
  { src: '/images/open_images.jpg', title: 'Google Open Images Object Detection', full_img: '/images/open_images_full.png' },
  { src: '/images/severstal.png', title: 'Severstal Steel Defect Detection', full_img: '/images/severstal_full.png' },
  { src: '/images/HPA.png', title: 'Human Protein Atlas Image Classification', full_img: '/images/HPA_full.png' },
  { src: '/images/skill_assessment.png', title: 'Surgical Skill Assessment', full_img: '/images/skill_assessment_full.png' },
];

Modal.setAppElement('#root');

const Portfolio = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div id="portfolio" className="portfolio-section">
      <div className="portfolio-section-title">Selected Works</div>
      <div className="portfolio-section-grid">
        {items.map((item, i) => (
          <div
            className="portfolio-section-item"
            key={i}
            onClick={() => {
              if (item.full_img) {
                openModal(item);
              } else if (item.link) {
                window.open(item.link, '_blank');
              }
            }}
          >
            <div
              className="portfolio-section-image"
              style={{ backgroundImage: `url(${item.src})` }}
            >
              <div className="overlay">
                <div className="overlay-text">{item.title}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Portfolio Item Modal"
        className="portfolio-modal"
        overlayClassName="portfolio-modal-overlay"
      >
        {selectedItem && (
          <>
            <button className="close-button" onClick={closeModal}>
              X
            </button>
            <img src={selectedItem.full_img} alt={selectedItem.title} />
          </>
        )}
      </Modal>
    </div>
  );
};

export default Portfolio;
