import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import MainContent from './components/MainContent/MainContent';
import Home from './pages/Home';

function App() {
    return (
        <Router>
            <div className="App">
                <Sidebar />
                <MainContent>
                    <Home />
                </MainContent>
            </div>
        </Router>
    );
}

export default App;
