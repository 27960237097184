import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { slide as Menu } from 'react-burger-menu';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import { SiBluesky } from 'react-icons/si';

const Sidebar = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToSection = (sectionId) => {
        console.log(`Scrolling to section: ${sectionId}`);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const sidebarContent = (
        <div className="Sidebar-content">
            <img src="/images/logo.svg" alt="Logo" className="Sidebar-logo" />
            <div className="Sidebar-link-wrapper">
                <a href="/resume.pdf" target="_blank" rel="noopener noreferrer" className="Sidebar-menu-link">CV</a>
            </div>
            <div className="Sidebar-link-wrapper">
                <div onClick={() => scrollToSection('portfolio')} className="Sidebar-menu-link">Portfolio</div>
            </div>
            <div className="Sidebar-link-wrapper">
                <div onClick={() => scrollToSection('contact')} className="Sidebar-menu-link">Contact</div>
            </div>
            <div className="Sidebar-social">
                <a href="https://www.linkedin.com/in/sanchit-hira/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="Sidebar-social-icon" />
                </a>
                <a href="https://github.com/sanch7" target="_blank" rel="noopener noreferrer">
                    <FaGithub className="Sidebar-social-icon" />
                </a>
                <a href="https://bsky.app/profile/sanchithira76.bsky.social" target="_blank" rel="noopener noreferrer">
                    <SiBluesky className="Sidebar-social-icon" />
                </a>
            </div>
        </div>
    );

    return isMobile ? <Menu>{sidebarContent}</Menu> : <div className="Sidebar">{sidebarContent}</div>;
};

export default Sidebar;
