import React, { useEffect, useState } from 'react';
import './Home.scss';
import Skills from './Skills';
import Achievements from './Achievements';
import WorkExperience from './WorkExperience';
import Portfolio from './Portfolio';
import Contact from './Contact';

const Home = () => {
  const [introLines, setIntroLines] = useState([]);

  useEffect(() => {
    const textToType = "I am an AI/ML Engineer";
    const typingSpeed = 100;

    const createTypingEffect = async (text) => {
      const lines = [''];
      let lineIndex = 0;
      let charIndex = 0;

      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (charIndex < text.length) {
            lines[lineIndex] += text[charIndex];
            charIndex++;
          } else {
            clearInterval(intervalId);
            resolve();
          }

          setIntroLines([...lines]);
        }, typingSpeed);
      });
    };

    const startTyping = async () => {
      await createTypingEffect(textToType);
    };

    startTyping();
  }, []);

  return (
    <div>
      <section id="intro" className="IntroSection">
        <div className="IntroTitle">
          {introLines.map((line, index) => (
            <div key={index} className="TypingLine">
              {line}
              {index === introLines.length - 1 && (
                <span className="Caret">|</span>
              )}
            </div>
          ))}
        </div>
        <div className="Education">
          <p>Education</p>
          <p>Johns Hopkins University (2018-2020)</p>
          <p>BITS Pilani, Pilani (2014-2018)</p>
        </div>
      </section>

      <WorkExperience />
      <Skills />
      <Achievements />
      <Portfolio />
      <Contact />
    </div>
  );
};

export default Home;
