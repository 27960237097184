import React from "react";
import "./WorkExperience.scss";

const experiences = [
  { date: "November 2020 - Present", title: "Apple - Senior AI/ML Software Engineer", icon: <img src="/images/apple_logo.svg" alt="Apple Logo" className="work-experience-logo" /> },
  { date: "July 2020 - October 2020", title: "Intuitive Surgical - CV Intern", icon: "🩺" },
  { date: "Feb 2019 - May 2020", title: "VecTech - CV Researcher", icon: "📐" },
  { date: "Jan 2019 - May 2020", title: "CIRL Lab, JHU - CV Researcher", icon: "🔬" },
  { date: "May 2019 - August 2019", title: "Dishcraft Robotics - CV Intern", icon: "🤖" },
];

const WorkExperience = () => {
  return (
    <div className="work-experience-container">
      <div className="work-experience-title">Work Experience</div>
      <div className="work-experience-list">
        {experiences.map((experience, index) => (
          <div className="work-experience-item" key={index}>
            <div className="work-experience-icon">{experience.icon}</div>
            <div className="work-experience-content">
              <div className="work-experience-date">{experience.date}</div>
              <div className="work-experience-role">{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkExperience;
