import React from 'react';
import './Contact.scss';

const Contact = () => {
  return (
    <div id="contact" className="contact-section">
      <div className="contact-section-title">Contact 💌</div>
      <div className="contact-section-text">
        The best way to reach me is via email. My address is my first name, then my last name, followed by the number 24 (without spaces), at Google's popular email service. (Think: firstname lastname 24 at gmail). Looking forward to hearing from you!
      </div>
    </div>
  );
};

export default Contact;

